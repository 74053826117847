import Vue from 'vue';
import Framework7 from 'framework7';
import { Mutations } from 'vuex-smart-module';

import { AppData, IDomainTokens, IMultipleSelect, IUser, DebugControl } from '@/types';
import { ISendStatRequest, LOCALSTORAGE_UUID } from '@/misc';

import Storage from '@/storage';
import {
  LOCALSTORAGE_APPDATA,
  LOCALSTORAGE_DEVICE,
  LOCALSTORAGE_DOMAIN,
  LOCALSTORAGE_DOMAIN_FIRST,
  LOCALSTORAGE_DOMAIN_TOKENS,
  LOCALSTORAGE_SECONDRUN,
  LOCALSTORAGE_STAT_QUEUE,
  LOCALSTORAGE_TOKEN,
  LOCALSTORAGE_PWA_DEBUG,
  LOCALSTORAGE_HOST,
  LOCALSTORAGE_LOCALE,
  LOCALSTORAGE_PUSHTOKEN,
  LOCALSTORAGE_VERBOSE,
} from '@/misc/const';

import config from 'src/app.config.js';
import transformAppDataSettings from '@/misc/transformAppDataSettings';
import * as API from '@/api';

import AppState from '@/store/app/state';

export default class AppMutations extends Mutations<AppState> {
  setCurrentTab(value: string) {
    this.state.currentTab = value;
  }

  setSecondRun(value: boolean) {
    this.state.secondRun = value;

    Storage.setSync(LOCALSTORAGE_SECONDRUN, value);
  }

  setMultipleSelect(params: IMultipleSelect) {
    this.state.multipleSelect = params;
  }

  setMultipleSelectItem({
    index,
    checked,
    radio,
  }: {
    index: number;
    checked: boolean;
    radio: boolean;
  }) {
    if (this.state.multipleSelect) {
      if (radio) {
        this.state.multipleSelect.data.forEach((item, i) => {
          item.checked = i === index;
        });
      } else {
        Vue.set(this.state.multipleSelect.data[index], 'checked', checked);
      }
    }
  }

  resetMultipleSelect() {
    this.state.multipleSelect?.data.forEach((item) => {
      Vue.delete(item, 'checked');
    });
  }

  setOnline(value: boolean) {
    this.state.online = value;
  }

  setPanelOpened(value: boolean) {
    this.state.panelOpened = value;
  }

  setSwipePanel(value: boolean) {
    this.state.swipePanel = value;
  }

  setSwipeBack({ value, f7 }: { value: boolean; f7: Framework7 | undefined }) {
    this.state.swipeBack = value;

    if (f7?.views?.current?.params.iosSwipeBack != null) {
      f7.views.current.params.iosSwipeBack = value;
    }
  }

  setTabDidOpen(tabName: string) {
    Vue.set(this.state.tabDidOpen, tabName, true);
  }

  setAuthToken(value: string) {
    this.state.authToken = value;
    window.authToken = value;

    // save domain authToken
    if (this.state.domain) {
      Vue.set(this.state.domainTokens, this.state.domain, value);
      Storage.setSync(LOCALSTORAGE_DOMAIN_TOKENS, this.state.domainTokens);
    }

    Storage.setSync(LOCALSTORAGE_TOKEN, value);
  }

  setIdDevice(value: any) {
    this.state.idDevice = value;

    Storage.setSync(LOCALSTORAGE_DEVICE, value);
  }

  setResetTabs() {
    this.state.tabDidOpen = {};

    Vue.set(
      this.state.tabDidOpen,
      this.state.appData?.settings?.modules?.app?.defaultTab || config.modules.defaultTab,
      true,
    );
  }

  setAppData(data: AppData) {
    const transformedData = transformAppDataSettings(data);

    Object.freeze(transformedData);

    this.state.appData = transformedData;

    Storage.setSync(LOCALSTORAGE_APPDATA, transformedData);
  }

  setAppDataDidLoad(value: boolean) {
    this.state.appDataDidLoad = value;
  }

  setAppDataDidFail(value: boolean) {
    this.state.appDataDidFail = value;
  }

  setRegDeviceDidFail(value: boolean) {
    this.state.regDeviceDidFail = value;
  }

  setAppDataLoading(value: boolean) {
    this.state.appDataLoading = value;
  }

  setAppDataPromise(value: Promise<any> | null) {
    this.state.appDataPromise = value;
  }

  setUser(user: IUser) {
    const appData: AppData = JSON.parse(JSON.stringify(this.state.appData));
    appData.user = user;

    Object.freeze(appData);
    this.state.appData = appData;

    Storage.setSync(LOCALSTORAGE_APPDATA, appData);
  }

  setSplashVisibility(value: boolean) {
    this.state.splashVisible = value;
  }

  setDomain(domain: relatedDomain) {
    this.state.domain = domain;

    Storage.setSync(LOCALSTORAGE_DOMAIN, domain);

    API.setBaseUrl(domain, this.state.host);
  }

  setDomainTokens(value: IDomainTokens) {
    this.state.domainTokens = value;
  }

  setDomainFirstSelect(value: boolean) {
    this.state.domainFirstSelect = value;

    Storage.setSync(LOCALSTORAGE_DOMAIN_FIRST, value);
  }

  setLazyLoadedImagesUrls(url: string) {
    if (!this.state.lazyLoadedImagesUrls.includes(url)) {
      this.state.lazyLoadedImagesUrls.push(url);
    }
  }

  setStatQueue(queue: ISendStatRequest[]) {
    this.state.statQueue = queue;
  }

  pushStatQueue(stat: ISendStatRequest) {
    this.state.statQueue.push(stat);

    Storage.setSync(LOCALSTORAGE_STAT_QUEUE, this.state.statQueue);
  }

  spliceStatQueue(index: number) {
    this.state.statQueue.splice(index, 1);

    Storage.setSync(LOCALSTORAGE_STAT_QUEUE, this.state.statQueue);
  }

  setStatInQueue(stat: Nullable<ISendStatRequest>) {
    this.state.statInQueue = stat;
  }

  setDebugControl(value: DebugControl) {
    this.state.debugControl = value;
  }

  setCurrentCoordinates(value: Coords) {
    this.state.currentCoords = value;
  }

  setDebugPWA(value: boolean) {
    this.state.debugPWA = value;

    Storage.setSync(LOCALSTORAGE_PWA_DEBUG, value);
  }

  setLocale(value: string) {
    this.state.locale = value;

    Storage.setSync(LOCALSTORAGE_LOCALE, this.state.locale);
  }

  setHost(value: 'client' | 'gmb' | 'dev') {
    this.state.host = value;

    Storage.setSync(LOCALSTORAGE_HOST, this.state.host);
  }

  setUuid(value: string) {
    this.state.uuid = value;

    Storage.setSync(LOCALSTORAGE_UUID, value);
  }

  setPushToken(value: Nullable<string>) {
    this.state.pushToken = value;

    Storage.setSync(LOCALSTORAGE_PUSHTOKEN, value);
  }

  setVerbose(value: boolean) {
    this.state.verbose = value;

    Storage.setSync(LOCALSTORAGE_VERBOSE, value);
  }
}
