import axios, { AxiosRequestConfig, CancelTokenSource, Method } from 'axios';
import queryString from 'query-string';

import { AppData, cancelToken } from 'src/types';

import i18n from 'src/translate/lang';
import config from 'src/app.config.js';
import deployJSON from 'src/../deploy.json';

import { getGeocoderSuggestions, getGeocoderAddress } from '@/api/geocoder';
export { getGeocoderSuggestions, getGeocoderAddress };

import { API_ENDPOINT_APPDATA, LOCALSTORAGE_DOMAIN } from '@/misc/const';
import Store from '@/storage';

const clientAPI = axios.create();
const coreAPI = axios.create({
  baseURL: config.api.core,
});
const staticAPI = axios.create({
  baseURL: 'https://static.wellsales.ru',
});

export type TResponse = {
  status?: 'success' | 'error';
  cancelToken?: any;
};

export interface IAPIRequest {
  method: Method;
  url: string;
  data?: {
    [key: string]: any;
  };
  params?: {
    [key: string]: any;
  };
  cancelToken?: cancelToken;
  keepCancelToken?: boolean;
  api?: 'client' | 'core' | 'static';
  timeout?: number;
  forceError?: boolean;
}

export const API_ENDPOINT_SCREENRATIOS = 'pwa/ratios.json';

export function baseURL(client?: string, host?: 'client' | 'gmb' | 'dev'): string {
  const cfg: any = {
    protocol: config.api.protocol,
    client: client || config.api.client,
    host: host === 'dev' ? 'gmb-dev.ru' : config.api.host,
    apiPath: config.api.apiPath,
    apiVersion: config.api.apiVersion,
  };

  const qs = queryString.parseUrl(window.location.href);

  if (qs.query) {
    Object.keys(cfg).forEach((key) => {
      cfg[key] = qs.query[key] ?? cfg[key];
    });
  }

  return `${cfg.protocol}://${cfg.client}.${cfg.host}${cfg.apiPath}${cfg.apiVersion}`;
}

export function setBaseUrl(domain: string, host?: 'client' | 'gmb' | 'dev') {
  clientAPI.defaults.baseURL = baseURL(domain, host);
}

export function getBaseUrlOnlyHost(): string {
  return clientAPI.defaults.baseURL!.replace(
    `${config.api.apiPath}${config.api.apiVersion}`,
    '',
  );
}

export async function request<T>(
  {
    method,
    url,
    data,
    params,
    cancelToken,
    keepCancelToken,
    api,
    timeout,
    forceError,
  }: IAPIRequest,
  mock?: T, // Return mocked response without actually making a real request
  offline?: T, // Return mocked response if server is down or request returned an error
): Promise<T & TResponse> {
  let source: CancelTokenSource | null = null;

  const headers: { [key: string]: any } = {
    'Accept-Language': i18n.locale,
  };

  if (api !== 'static') {
    headers['App-Version'] = deployJSON.version;
  }

  const requestParams: AxiosRequestConfig = {
    data,
    params,
    method,
    url,
    headers,
  };

  if (queryString.parse(window.location.search)?.vk_user_id) {
    requestParams.headers['Mini-app'] = 'vk';
  }

  if (!cancelToken && !mock) {
    source = axios.CancelToken.source();
    cancelToken = source.token;
    requestParams.cancelToken = cancelToken;

    window.cancelRequestHandle = source;
  }

  if (mock) {
    return new Promise((resolve) => {
      setTimeout(() => {
        return resolve({ ...mock });
      }, 1000);
    });
  } else {
    try {
      const axiosInstance =
        api === 'static' ? staticAPI : api === 'core' ? coreAPI : clientAPI;

      let { data: response }: { data: T & TResponse } = await axiosInstance.request(
        requestParams,
      );

      if (keepCancelToken && cancelToken) {
        response = {
          cancelToken,
          ...response,
        };
      }

      if (response.status === 'error') {
        throw response;
      }

      if (timeout) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            if (forceError) {
              return reject(response);
            } else {
              return resolve(response);
            }
          }, timeout);
        });
      } else {
        if (forceError) {
          throw response;
        } else {
          return response;
        }
      }
    } catch (error) {
      window.cancelRequestHandle = null;

      if (process.env.VUE_APP_AUTHORIZED === 'true' && offline) {
        return Promise.resolve({ ...offline });
      } else {
        throw error;
      }
    } finally {
      if (!keepCancelToken) {
        window.cancelRequestHandle = null;
      }
    }
  }
}

export async function getAppData(): Promise<AppData> {
  return request<AppData>({
    method: 'post',
    url: API_ENDPOINT_APPDATA,
    data: {
      location: window.location,
    },
  }).then((response) => {
    if (window.innerWidth > 600) {
      response.settings.modules.app.fullscreenLogo = false;
      response.settings.modules.app.fullscreenLogoParallax = false;
      response.settings.modules.app.hideTitleWhenFullscreenLogo = false;
    }

    response.timestamp = +new Date();

    // Catalog History Bar and Basket Bar
    if (response.settings.modules.catalog) {
      // @ts-ignore
      response.settings.slots?.home?.['home-slot-top']?.push('catalog-home-slot-padding');

      if (!response.settings.slots.app) {
        response.settings.slots.app = {};
      }

      if (!response.settings.slots.app['tabbar-slot']) {
        response.settings.slots.app['tabbar-slot'] = [];
      }

      response.settings.slots.app['tabbar-slot'].push('catalog-tabbar-slot');
      response.settings.slots.app['tabbar-slot'].push('history-bar');
    }

    if (response.settings.modules.panelmenu == null) {
      response.settings.modules.panelmenu = {};
    }

    if (window.devMode) {
      // Bonus Card Main Screen Preview
      // // @ts-ignore
      // response.settings.modules.app.smallLogo = true;
      // // @ts-ignore
      // response.settings.modules.app.hideLogo = true;
      // // @ts-ignore
      // response.settings.modules.bonus.homeBonusButtonType = 'card';
      // Catalog Badge Preview
      // // @ts-ignore
      // response.settings.modules.app.catalogItemShowLabels = true;
      // Example
      // @ts-ignore
      // response.settings.modules.auth.receiptToEmailModuleEnabled = true;
    }

    return response;
  });
}

export function getSafariScreenSizes(): Promise<ScreenRatio> {
  return request<string | ScreenRatio>({
    method: 'get',
    url: API_ENDPOINT_SCREENRATIOS,
    api: 'static',
  }).then((response) => {
    if (typeof response === 'string') {
      return JSON.parse(response);
    } else {
      return response;
    }
  });
}

clientAPI.defaults.timeout = 100 * 1000;

clientAPI.interceptors.request.use(
  (config) => {
    if (window.authToken) {
      config.headers.Authorization = `token ${window.authToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

clientAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code === 'ECONNABORTED') {
      error.message = i18n.t('global.misc.timeoutExceeded');
    }

    return Promise.reject(error);
  },
);

coreAPI.interceptors.request.use(
  (config) => {
    config.headers['use-project'] = Store.getSync(LOCALSTORAGE_DOMAIN);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
